import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

import { motion } from "framer-motion"

const ProfitAccumulator = () => (
  <Layout>
    <SEO title="Profit Accumulator - UK Based Freelance Web Designer/Developer" />
    <div className="container mx-auto px-4 max-w-7xl relative z-20">
      <div className="rounded-2xl overflow-hidden relative">
        <StaticImage className="absolute left-0 top-0 w-full h-full rounded-2xl overflow-hidden round-img z-10 work-hero" src="../images/profitaccumulator-thumb.jpg" alt="Profit Accumulator" />
        <div className="w-full bg-zinc-900 bg-opacity-60 md:bg-opacity-20 bg-gradient-to-t from-zinc-900 to-transparent pt-24 md:pt-36 rounded-2xl relative z-20">
            <div className="p-8 md:p-12 lg:p-16 text-white  w-full">
                      <motion.h1 initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0 }} viewport={{ once: true }} className="text-5xl md:text-6xl flex flex-wrap lg:text-7xl md:max-w-sm lg:max-w-lg xl:max-w-xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tighter">Profit Accumulator</motion.h1>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10 lg:gap-20 xl:gap-24 pt-6 md:pt-10">
          <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.075 } }} viewport={{ once: true }}>
            <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-3 md:mb-4">
                About the Client
            </div>
            <div className="text-base md:text-lg lg:text-xl lg:leading-normal text-white md:opacity-90 font-light">
              <p>
                A subscription service that teaches people in the UK how to take advantage of free bets and bonuses given out by bookmakers to make guaranteed profits. 
              </p>
              <p className="text-xs md:text-sm lg:text-base mt-3 opacity-75">(Profit Accumulator has since rebranded to "Outplayed.com", with a new design not provided by me)</p>
            </div>
          </motion.div>
          <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.1 } }} viewport={{ once: true }}>
            <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-3 md:mb-4">
                Overview of my work
            </div>
            <div className="text-base md:text-lg lg:text-xl lg:leading-normal text-white md:opacity-90 font-light">
              <ol className="space-y-2 md:space-y-3">
                <li className="flex items-start space-x-1">
                  <span>1.</span>
                  <span>Full Profit Accumulator website redesign</span>
                </li>
                <li className="flex items-start space-x-1">
                  <span>2.</span>
                  <span>Profit Accumulator logo redesign (unused)</span>
                </li>
                <li className="flex items-start space-x-1">
                  <span>3.</span>
                  <span>Images for social media ad campaign</span>
                </li>
                <li className="flex items-start space-x-1">
                  <span>4.</span>
                  <span>Bonus Accumulator web & logo design</span>
                </li>
              </ol>
            </div>
          </motion.div>
        </div>
            </div>
          </div>
      </div>
        
        
    </div>

    <motion.div initial={{ opacity: 0, y: 15 }} whileInView={{ opacity: 1, y: 0, transition: { delay: 0.125 } }} viewport={{ once: true }} className="relative z-20 pt-24 md:pt-28 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2  className="text-3xl md:text-4xl lg:text-5xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight text-zinc-900 mb-4 md:mb-5">
          <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>1. </span>
          Profit Accumulator Website Redesign
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              Profit Accumulator initially required a redesign of the homepage to advertise their service and maximise conversions. After the homepage, I went on to redesign their entire website (over 30+ pages), including the public pages as well as some of their members-only tools.
            </p>
            <p>The client already had a web developer on board, so I made sure to structure my design files well and provide plenty of documentation to ensure a smooth hand-off.</p>
          </div>
          
        </div>
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-2">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700">
              <p>
               Web Design
              </p>
            </div>
          </div>
          
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-72 md:h-[800px] col-span-8 lg:col-span-9 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-1.png" alt="Profit Accumulator" />
          <StaticImage className="h-72 md:h-[800px] col-span-4 lg:col-span-3 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-2.png" alt="Profit Accumulator" />
          <StaticImage className="h-72 md:h-auto xl:h-[800px] col-span-12 md:col-span-4 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-3.png" alt="Profit Accumulator" />
          <StaticImage className="h-72 md:h-auto xl:h-[800px] col-span-12 md:col-span-4 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-4.png" alt="Profit Accumulator" />
          <StaticImage className="h-72 md:h-auto xl:h-[800px] col-span-12 md:col-span-4 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-5.png" alt="Profit Accumulator" />
          <StaticImage className="h-72 md:h-auto xl:h-[600px] col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-6.png" alt="Profit Accumulator" />
          <StaticImage className="h-72 md:h-auto xl:h-[600px] col-span-12 md:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-7.png" alt="Profit Accumulator" />
        </div>
      </div>
      
    </motion.div>
    <div className="relative z-20 pt-24 md:pt-28 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2  className="text-3xl md:text-4xl lg:text-5xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight text-zinc-900 mb-4 md:mb-5">
          <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>2. </span>
          Profit Accumulator Logo Redesign (unused)
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              I explored a new brand identity for Profit Accumulator to go along with the new website redesign. However, these logo ideas went unused as the client was not ready to make a significant change to the logo at the time.
            </p>
          </div>
          
        </div>
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-2">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700">
              <p>
               Logo Design
              </p>
            </div>
          </div>
          
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-auto col-span-12 lg:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-logo-1.png" alt="Profit Accumulator Logos" />
          <StaticImage className="h-auto col-span-12 lg:col-span-6 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-logo-2.png" alt="Profit Accumulator Logos" />

        </div>
      </div>
      
    </div>
    <div className="relative z-20 pt-24 md:pt-28 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2  className="text-3xl md:text-4xl lg:text-5xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight text-zinc-900 mb-4 md:mb-5">
          <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>3. </span>
          Images for Social Media Ad Campaign
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              Profit Accumulator wanted to run an ad campaign to increase sales. I was given 5 different "themes" and I provided Facebook ad compliant images with a few different variations for each theme.
            </p>
          </div>
          
        </div>
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-2">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700">
              <p>
               Graphic Design
              </p>
            </div>
          </div>
          
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-auto col-span-12 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/profitaccumulator-ad-1.png" alt="Profit Accumulator Ad Images" />

        </div>
      </div>
      
    </div>
    <div className="relative z-20 pt-24 md:pt-28 lg:pt-36">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2  className="text-3xl md:text-4xl lg:text-5xl font-medium leading-tight md:leading-tight lg:leading-tight tracking-tight text-zinc-900 mb-4 md:mb-5">
          <span className="bg-clip-text text-transparent bg-gradient-to-tr from-indigo-700 to-fuchsia-500" style={{WebkitBoxDecorationBreak : 'clone'}}>4. </span>
          Bonus Accumulator Web & Logo Design
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-5 md:gap-10 lg:gap-20 xl:gap-24">
        <div className="md:col-span-8">
          <div className="text-sm md:text-base lg:text-lg text-zinc-500 space-y-3 md:space-y-4">
            <p>
              Profit Accumulator wanted to create a new offering called "Bonus Accumulator", solely focused on Casino Offers. I chose a new colour scheme, designed a logo & provided a full homepage design.
            </p>
          </div>
          
        </div>
        <div className="md:col-span-4">
        <div className="uppercase tracking-wider text-sm md:text-base font-medium mb-2 md:mb-2.5 md:mt-2">
                Services I Provided
            </div>
            <div className="text-sm md:text-base text-zinc-700">
              <p>
               Web design, Logo Design
              </p>
            </div>
          </div>
          
        </div>
        
      </div>
      <div className="mx-auto px-4 max-w-[1536px] pt-10 md:pt-16">
        <div className="grid grid-cols-12 gap-4 md:gap-6 lg:gap-8">
          <StaticImage className="h-72 md:h-[800px] col-span-8 lg:col-span-9 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/bonusaccumulator-1.png" alt="Bonus Accumulator" />
          <StaticImage className="h-72 md:h-[800px] col-span-4 lg:col-span-3 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/bonusaccumulator-2.png" alt="Bonus Accumulator" />
          <div className="col-span-12 md:col-span-5 relative">
            <StaticImage className="h-auto md:h-full md:absolute rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/bonusaccumulator-3.png" alt="Bonus Accumulator" />
          </div>
          <StaticImage className="h-auto col-span-12 md:col-span-7 rounded-xl md:rounded-2xl overflow-hidden border border-zinc-200 shadow-lg round-img-sm" objectPosition="center top" src="../images/bonusaccumulator-4.png" alt="Bonus Accumulator" />
        </div>
      </div>
      
    </div>
    <CTA></CTA>
  </Layout>
)

export default ProfitAccumulator
